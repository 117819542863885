
$color-background: #212121;
$color-text-header: #00AEEF;
$color-text-main: #212121;
$color-shadow: #C1C1C1;
$color-accent: #DD4F12;
$color-accent2: #4ca960;
$color-gray: #555454;
$color-white: #ffffff;
$padding-min: 5px;
$padding-default: 10px;
$margin-default: 10px;

.html {
  scroll-behavior: smooth;
}

.body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: $color-background;
}

.container {
  background: $color-background;
  text-align: center;
  padding: 20px;
}

.nav-bar {
  margin: 0;
  padding: $padding-min 0 $padding-min 0;
  display: block;
  font-size: large;
  background: $color-background;
  color: $color-text-header;
}

.nav-item {
  list-style-type: none;
}

.nav-link {
  font-size: large;
  text-decoration: none;
  background: $color-background;
  color: $color-text-header;
}

.nav-link:hover {
  font-size: large;
  background: $color-shadow;
  color: $color-text-header
}

//Buttons
.button-main {
  display: inline-block;
  border: 0.1em solid $color-accent2;
  margin: 5px 10px 5px 10px;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  color: $color-white;
  padding: $padding-default;
  background-color: $color-accent2;
  text-align: center;
  transition: all 0.2s;
}

.button-main:hover {
  color: $color-white;
  background-color: $color-gray;
}

.button-image {
  display: inline-block;
  border: 0.1em solid $color-accent2;
  margin: $margin-default;
  box-sizing: border-box;
  text-decoration: none;
  color: $color-gray;
  padding: $padding-default;
  background-color: $color-background;
  text-align: center;
  transition: all 0.2s;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}

.button-image:hover {
  color: $color-gray;
  background-color: $color-gray;
}

.button-maps {
  background: $color-text-header;
  padding: $padding-default;
  color: $color-background;
}

.button-maps:hover {
  color: $color-white;
  background-color: $color-gray;
}

.button-link {
  color: #00AEEF;
  display: grid;
  align-items: center;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  text-decoration: none;
}

.button-link:hover {
  color: rgba(0, 174, 239, 0.63);
  display: grid;
  align-items: center;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  align-self: center;
  text-decoration: none;
}

.button-black {
  position: relative;
  display: block;
  background-color: #333;
  border: none;
  height: auto;
  font-size: 15px;
  margin: 20px;
  display: block;
  color: #ffffff;
  padding: 10px;
  width: auto;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button-black:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.button-black:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

//Card
.card {
  color: #eeeeee;
  padding: $padding-min;
  text-align: center;
  margin: 20px;
  border: 2px solid #d4d4d4;
  border-radius: 15px 50px 30px;
}

//Headers
.header {
  color: $color-white;
  text-align: center;
  font-size: large;
  font-style: oblique;
}

//Divs

.div-center {
  height: auto;
  width: auto;
  alignment: center;
  color: black;
  align-content: center;
  text-align: center;
  margin: 16px;
}

.div-default {
  margin: 16px;
}

.div-box {
  text-decoration: none;
  background-color: #eee;
  padding: 5px;
  text-align: center;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  display: block;
}

.div-box-black{
  text-decoration: none;
  margin: 5px;
  background-color: $color-gray;
  padding: 5px;
  text-align: center;
  height: auto;
  border-radius: 4px;
  display: block;
}

//Progress
.progress-indicator {
  margin: 20px;
}

//Text views
.text-default {
  width: auto;
  color: $color-accent;
  font-size: 12pt;
  font-weight: bold;
  margin: 8px;
}

.text-default-inline {
  width: auto;
  color: $color-accent;
  font-size: 12pt;
  display: inline-block;
  font-weight: bold;
  margin: 8px;
}

//Tables

.table-header {
  color: $color-white;
  font-size: medium;
  margin: 20px;
}

.table {
  width: auto;
  //margin: 20px;
  color: $color-white;
  display: inline-table;
  table-layout: auto;
}

.table th {
  color: $color-white;
  text-align: start;
  //background: $color-accent2;
  background: black;
  padding: $padding-default;
}

.table td {
  border: 0px solid $color-white;
  color: black;
  //color: $color-white;
  padding: $padding-default;
  text-align: start;
}

.table tr:hover {
  background-color: #ddd;
}

.table tr:nth-child(odd) {
  background-color: #3e4049;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

.table tr:nth-child(even) {
  background-color: #4a4f61;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}


//Headers
h1 {
  color: $color-white;
}

h2 {
  color: $color-white;
}

h3 {
  color: $color-white;
}

h4 {
  color: $color-white;
}

h5 {
  color: $color-white;
}

h6 {
  color: $color-white;
}

//Navbar

/* Add a black background color to the top navigation */
.navigation-bar {
  background-color: #333;
  overflow: hidden;
  display: flex;
  justify-content: flex-start; /* Все элементы по умолчанию слева */
  align-items: center;
}

.right-item {
  margin-left: auto; /* Перемещает элемент вправо */
}

/* Style the links inside the navigation bar */
.navigation-bar a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.navigation-bar a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.navigation-bar a.active {
  background-color: #04aa6d;
  color: white;
}


// Titles
.title-base {
  font-style: oblique;
  color: $color-white;
  text-align: center;
}

.title-base-left {
  font-style: oblique;
  color: $color-white;
  text-align: start;
}

// List items

.list {
  padding: 5px;
  display: inline-block;
  text-align: center;
  width: auto;
  align-self: center;
  list-style-type: none;
  margin: auto;
}

.list li {
  position: relative;
  padding: 10px;
  height: auto;
  cursor: pointer;
  display: flex;
  width: auto;
  margin: auto;
  align-content: center;
}

.list li:hover {
  left: 0.1em;
}

.list a {
  color: black;
  margin: auto;
  text-decoration: none;
  background-color: #eee;
  padding: 20px;
  text-align: center;
  height: auto;
  border-radius: 4px;
  display: block;
  width: 100%;
}

.list a:hover {
  color: #2c3a41;
  background-color: #e6e6e6;
}

.list a:active {
  background-color: #525252;
  color: #fafafa;
}


// Input

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $color-gray;
  outline: 0;
  font-size: 1.3rem;
  color: $color-white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $color-gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $color-accent;
    font-weight:700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  //border-image: linear-gradient(to right, $primary,$secondary);
  //border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

/* Importing Bootstrap SCSS file. */
//@use '~bootstrap/scss/bootstrap';
@import "../../node_modules/bootstrap/scss/bootstrap";
//@import "../../node_modules/ngx-toastr/toastr.css";

// Modals

.modal-header h1 {
  color: $color-background;
}

/* TOASTR BUGFIX */
#toast-container > div {
  opacity: 1;
}
.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}
.toast-success {
  background-color: #51A351 !important;
}
.toast-error {
  background-color: #BD362F !important;
}
.toast-info {
  background-color: #2F96B4 !important;
}
.toast-warning {
  background-color: #F89406 !important;
}

//Columns
.column-center {
  float: left;
  width: 33.33%;
  align-content: center;
  align-self: center;
  text-align: center;
}

//P
p {
  color: $color-background
}

.p-black {
  color: $color-background
}

.p-base {
  color: $color-text-main;
}

//
.column {
  float: left;
  width: 33.33%;
  text-align: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.content {
  margin: 20px;
}

.default-input {
  margin: 20px;
}
